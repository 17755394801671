import request from '@/utils/request'


// 查询视频演员列表
export function listVideoPerformer(query) {
  return request({
    url: '/video/video-performer/list',
    method: 'get',
    params: query
  })
}

// 查询视频演员分页
export function pageVideoPerformer(query) {
  return request({
    url: '/video/video-performer/page',
    method: 'get',
    params: query
  })
}

export function changeIsBen(data) {
  return request({
    url: '/video/video-performer/changeIsBen',
    method: 'post',
    data: data
  })
}
// 查询视频演员详细
export function getVideoPerformer(data) {
  return request({
    url: '/video/video-performer/detail',
    method: 'get',
    params: data
  })
}

// 新增视频演员
export function addVideoPerformer(data) {
  return request({
    url: '/video/video-performer/add',
    method: 'post',
    data: data
  })
}

// 修改视频演员
export function updateVideoPerformer(data) {
  return request({
    url: '/video/video-performer/edit',
    method: 'post',
    data: data
  })
}

// 删除视频演员
export function delVideoPerformer(data) {
  return request({
    url: '/video/video-performer/delete',
    method: 'post',
    data: data
  })
}
